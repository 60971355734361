<script setup lang="ts">
import dayjs from 'dayjs';

import type { PurchaseLogSchema } from '~/schemas/purchase_log_schema';

interface Props {
  log: PurchaseLogSchema;
}

const props = withDefaults(defineProps<Props>(), {});

const config = useRuntimeConfig();

dayjs.locale('ja-jp'); // eslint-disable-line import/no-named-as-default-member

const format = (date: string) => {
  return dayjs(date).format('YYYY/MM/DD HH:mm:ss');
};

const paymentMethodName = (method: string, status: string) => {
  const getMethodDisplay = (m: string) => {
    switch (m) {
      case 'cash':
        return `現金`;
      case 'credit_card_3d_secure_dgft_veri-trans4g_mdk':
        return `クレジットカード`;
      case 'paypay_dgft_veri-trans4g_mdk':
        return `PayPay`;
      case 'bnpl':
        return `後払い`;
      case 'no_charge':
        return `クーポン`;
      default:
        return `不明`;
    }
  };

  const getStatus = (s: string) => {
    switch (s) {
      case 'pending':
        return '未払い';
      case 'paid':
        return '支払済み';
      case 'canceled':
        return 'キャンセル';
      case 'refunded':
        return '返金済み';
      case 'failed':
        return '失敗';
      default:
        return '不明';
    }
  };

  return `${getMethodDisplay(method)} (${getStatus(status)})`;
};

const tradelawUrl = computed(() => {
  switch (props.log.sales_base.id) {
    case config.public.BASE_ZEN_ID:
      return config.public.BASE_ZEN_COMMERCE_URL;
    case config.public.BASE_UKI_ID:
      return config.public.BASE_UKI_COMMERCE_URL;
    case config.public.BASE_JOY_ID:
      return config.public.BASE_JOY_COMMERCE_URL;
    case config.public.BASE_SEN_ID:
      return config.public.BASE_SEN_COMMERCE_URL;
    default:
      return null;
  }
});

const data = computed(() => {
  return [
    {
      name: '事業所',
      value: `${props.log.location.full_name}（BOX - ${props.log.location.location_number}）`,
    },
    {
      name: '冷凍庫',
      value: `${props.log.position.name}`,
    },
    {
      name: '決済',
      value: `${paymentMethodName(
        props.log.payment_method,
        props.log.payment_status,
      )}`,
    },
    {
      name: '決済日時',
      value: `${props.log.payment_at ? format(props.log.payment_at) : '-'}`,
    },
  ];
});
</script>

<template>
  <div class="mb-3 divide-y border-b">
    <div
      v-for="(item, i) in data"
      :key="i"
      class="grid grid-cols-3 pb-1 pt-4 text-gray-900 sm:grid-cols-5"
    >
      <div class="font-medium leading-6 sm:col-span-1">{{ item.name }}</div>
      <div class="col-span-2 sm:col-span-4">{{ item.value }}</div>
    </div>
    <div class="grid grid-cols-3 pb-1 pt-4 text-gray-900 sm:grid-cols-5">
      <div class="font-medium leading-6 sm:col-span-1">販売</div>
      <div class="col-span-2 sm:col-span-4">
        <p>{{ props.log.sales_base.name }}</p>
        <a
          v-if="tradelawUrl"
          :href="tradelawUrl"
          class="text-sm text-blue-600 underline decoration-blue-600 hover:text-blue-400 hover:decoration-blue-400"
          target="_blank"
          rel="noopener noreferrer"
        >
          特定商取引法に基づく表記
        </a>
      </div>
    </div>
  </div>
</template>
