<script setup lang="ts">
interface Props {
  loading?: boolean;
  price: number;
  fixedPrice?: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  fixedPrice: null,
});

const isUpdatedPrice = computed(() => {
  return props.fixedPrice !== null && props.fixedPrice !== props.price;
});
</script>

<template>
  <div class="ml-auto mt-0 flex w-fit flex-col items-stretch">
    <div
      v-if="loading"
      class="flex flex-wrap justify-between gap-x-4 tracking-tight text-gray-900"
    >
      <div class="flex-none text-sm font-semibold">合計</div>
      <div class="ml-auto inline-flex h-8 flex-none animate-pulse">
        <div
          class="inline-block h-2 w-28 self-center rounded bg-slate-700"
        ></div>
      </div>
    </div>
    <template v-else>
      <div
        class="flex flex-wrap justify-between gap-x-4 tracking-tight text-gray-900"
      >
        <div class="flex-none text-sm font-semibold">合計</div>
        <component
          :is="!isUpdatedPrice ? 'div' : 'del'"
          class="ml-auto flex-none"
          :class="{
            'decoration-red-600': isUpdatedPrice,
          }"
        >
          <span
            class="font-semibold tracking-widest"
            :class="{
              'text-2xl': !isUpdatedPrice,
              'text-base': isUpdatedPrice,
            }"
          >
            {{ price.toLocaleString('ja-JP') }}
          </span>
          <span class="text-base">円(税込)</span>
        </component>
      </div>
      <ins
        v-if="isUpdatedPrice"
        class="flex flex-wrap justify-between gap-x-4 tracking-tight text-red-600 no-underline"
      >
        <div class="flex-none text-sm font-semibold">割引後合計</div>
        <div class="ml-auto flex-none">
          <span class="text-2xl font-semibold tracking-widest">
            {{ fixedPrice?.toLocaleString('ja-JP') }}
          </span>
          <span class="text-base">円(税込)</span>
        </div>
      </ins>
    </template>
  </div>
</template>
